import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import reducer from "./reducer";
import { jwtDecode } from "jwt-decode";

const initialState = {
  currentUser: null,
  openAddMember: false,
  openDialog: false,
  loading: false,
  alert: { open: false, severity: "info", message: "" },
  viewStudent: null,
  users: [],
  students: [],
  teachers: [],
  classes: [],
  subjects: [],
  exams: [],
  studentMarks: [],
  fees: [],
  profile: { open: false },
  editRow: { open: false, row: null },
  provideDetails: { open: false, student: null },
  provideMarks: { open: false, student: null },
  subjectsInClass: [],
  deleteDialog: { open: false, row: null },
  studentResult: [],
  edit: null,
  studentsInClass: [],
  mode: "light",
  // studentsInSubject: [],
};

const Context = createContext(initialState);

export const useValue = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storedMode = localStorage.getItem("mode");
  const [mode] = useState(storedMode === "dark" ? "dark" : "light");

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const mode = localStorage.getItem("mode");

    if (currentUser) {
      const token = currentUser.token;
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        dispatch({ type: "UPDATE_USER", payload: null });
        localStorage.removeItem("currentUser");
        dispatch({
          type: "UPDATE_ALERT",
          payload: {
            open: true,
            severity: "error",
            message: "Automatically Signed Out! Please sign in again",
          },
        });
      } else {
        if (mode) {
          dispatch({ type: "UPDATE_MODE", payload: mode });
        } else {
          dispatch({ type: "UPDATE_MODE", payload: "light" });
        }
        dispatch({ type: "UPDATE_USER", payload: currentUser });
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("mode", mode);
  }, [mode]);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default ContextProvider;
