// import AllRoutes from "./Routes.js";
import { BrowserRouter as Router } from "react-router-dom";
import ContextProvider from "./context/ContextProvider";
import Notification from "./Components/user/Notification.jsx";
import Loading from "./Components/user/Loading.jsx";
// import CheckConnection from "./Components/CheckConnection.jsx";
import { lazy, Suspense } from "react";
const AllRoutes = lazy(() => import("./Routes.js"));

function App() {
  return (
    <>
      {/* <CheckConnection> */}
      <ContextProvider>
        <Loading />
        <Notification />
        <Router>
          <Suspense fallback={<></>}>
            <AllRoutes />
          </Suspense>
        </Router>
      </ContextProvider>
      {/* </CheckConnection> */}
    </>
  );
}

export default App;
